import * as sha1 from 'js-sha1'
import * as qiniu from 'qiniu-js'
import { getToken } from '@/api/upload'
import tool from './tool'

const concatArr2Uint8 = str => {
  let tmp = []
  for (var i of str) tmp = tmp.concat(i)
  return new Uint8Array(tmp)
}

const Uint8ToBase64 = (u8Arr, urisafe) => {
  let CHUNK_SIZE = 0x8000 //arbitrary number
  let index = 0
  let length = u8Arr.length
  let result = ''
  let slice
  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length))
    result += String.fromCharCode.apply(null, slice)
    index += CHUNK_SIZE
  }
  return urisafe ? btoa(result).replace(/\//g, '_').replace(/\+/g, '-') : btoa(result)
}

const calcEtag = (sha1String, blockCount, prefix) => {
  let shA1 = sha1.digest
  if (!sha1String.length) return 'Fto5o-5ea0sNMlW_75VgGJCv2AcJ'
  let sha1Buffer = concatArr2Uint8(sha1String)
  // 如果大于4M，则对各个块的sha1结果再次sha1
  if (blockCount > 1) {
    prefix = 0x96
    sha1Buffer = shA1(sha1Buffer.buffer)
  } else {
    sha1Buffer = Array.apply([], sha1Buffer)
  }
  sha1Buffer = concatArr2Uint8([[prefix], sha1Buffer])
  return Uint8ToBase64(sha1Buffer, true)
}

const getEtag = buffer => {
  // sha1算法
  let shA1 = sha1.digest

  // 以4M为单位分割
  let blockSize = 4 * 1024 * 1024
  let sha1String = []
  let prefix = 0x16
  let blockCount = 0

  let bufferSize = buffer.size || buffer.length || buffer.byteLength
  blockCount = Math.ceil(bufferSize / blockSize)

  for (let i = 0; i < blockCount; i++) {
    sha1String.push(shA1(buffer.slice(i * blockSize, (i + 1) * blockSize)))
  }

  return calcEtag(sha1String, blockCount, prefix)
}

export const getQiniuToken = (data = { policy: { mimeLimit: 'image/*' } }) => {
  getToken(data).then(res => {
    tool.data.set('qiniuToken', res.data.token)
  })
}

const compressType = ['image/png', 'image/jpeg', 'image/bmp', 'image/webp']

export const uploadQiniu = (file, prefix = '') => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = function () {
      let fileKey = getEtag(this.result)
      if (compressType.includes(file.file.type)) {
        //如果是图片则压缩
        qiniu
          .compressImage(file.file, {
            quality: 0.6,
            noCompressIfLarger: true,
          })
          .then(data => {
            const observable = qiniu.upload(
              data.dist,
              prefix + fileKey + file.file.name.substring(file.file.name.lastIndexOf('.')),
              tool.data.get('qiniuToken')
            )
            observable.subscribe({
              error(err) {
                file.status = 'failed'
                file.message = '上传失败'
                // console.log('上传失败:', err)
                reject(err)
              },
              complete(res) {
                file.status = 'done'
                file.message = ''
                file.path = res.key
                // console.log('上传成功:', res)
                resolve(res)
              },
            })
          })
          .catch(err => {
            file.status = 'failed'
            file.message = '压缩失败'
            reject(err)
          })
      } else {
        //不是图片不需要压缩直接上传
        const observable = qiniu.upload(file.file, prefix + fileKey + file.file.name.substring(file.file.name.lastIndexOf('.')), tool.data.get('qiniuToken'))
        observable.subscribe({
          error(err) {
            file.status = 'failed'
            file.message = '上传失败'
            // console.log('上传失败:', err)
            reject(err)
          },
          complete(res) {
            file.status = 'done'
            file.message = ''
            file.path = res.key
            // console.log('上传成功:', res)
            resolve(res)
          },
        })
      }
    }
    reader.readAsArrayBuffer(file.file)
  })
}

export const uploadFile = (file, callback, prefix = 'upload/') => {
  if (Array.isArray(file)) {
    let n = file.length
    let i = 0
    file.forEach(item => {
      item.status = 'uploading'
      item.message = '上传中...'
      uploadQiniu(item, prefix)
        .then(res => {
          i++
          if (n == i) {
            callback({ status: 'done', data: res, err: null })
          }
        })
        .catch(err => {
          callback({ status: 'failed', data: null, err: err })
        })
    })
  } else {
    file.status = 'uploading'
    file.message = '上传中...'
    uploadQiniu(file, prefix)
      .then(res => {
        callback({ status: 'done', data: res, err: null })
      })
      .catch(err => {
        callback({ status: 'failed', data: null, err: err })
      })
  }
}
